import logo from './logo.svg';
import './App.css';
import Questionbank from './components/Questionbank';
import Questionbankfirst from "./components/Questionbankfirst";
import Questionbankdpp from "./components/Questionbankdpp";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
       <Routes>
        <Route path="/bl/:codess" element={<Questionbank/>} />
        <Route path="/:codess" element={<Questionbankfirst/>} />
        <Route path="/dpp/:codess" element={<Questionbankdpp/>} />
     </Routes>
    </div>
  );
}

export default App;
