import React, { useState, useEffect } from 'react'
import '../css/Questionbank.css';
import { useStopwatch } from 'react-timer-hook'
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import  axios  from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
  } from "react-router-dom";
import Que1 from '../images/Que1.png';
var answers = [];
export default function Questionbank() {
    var { codess } = useParams();
const [index, setindex] = useState(0);
const [selected, setselected] = useState("skip");
const [submit, setsubmit] = useState(false);
//const [answers,setanswers] = useState('');
const [booklet, setbooklet] = useState();
const [chaptername,setchaptername] = useState('');
const [chaptoeno, setchaptorno] = useState('');
const [exerciseCode, setexerciseCode] = useState('');
const [exerciseid, setexerciseid] = useState();
const [excerciseNo, setexcerciseNo] = useState();
const [firstqesNo,setfirstqesNo] = useState();
const [quescount, setquescount] = useState();
const [subject, setsubject] = useState('');
const [solution, setsolution] = useState('');
const [min, setmin] = useState();
const [sec, setsec] = useState();
var [marks, setmarks] = useState(0);

const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });
  

var score = 0;
var correct = 0;
const totalqsn = 7;
console.log(correct);


console.log(answers);
function Nexts(){
    if(index>=0 && index<totalqsn-1){
        console.log("clicked");
        if(answers[index]){
            answers.splice(index, 1, selected);
        }
else{
    answers.push(selected);
}


      
if(answers[index+1]){
    setindex(index+1);

    setselected(answers[index+1]);
}
else{
    setindex(index+1);

    setselected("skip");}
}
if(index == totalqsn-1){
 
    answers.push(selected);
    for (let i = 0; i < answers.length; i++) {
        console.log(answers[i] == solution[i]);
    if(answers[i] == solution[i]){
        setmarks(++marks);
        setmin(minutes);
        setsec(seconds);
        setsubmit(true);
       console.log(solution[i]); 
    }
    else{
        setmin(minutes);
        setsec(seconds);
        setsubmit(true);
    }
    
    }


}
}
function Prevs() {
    if(index>0){
    setindex(index-1);
    setselected(answers[selected])}
}
function optioSelected(){
    answers.splice(index, 1, selected);
}

async function getdata() {
   
        const data = await axios.get(`https://disha.speedlabs.in/${codess}`);
 
        setbooklet(data.data[0].BookletNo);
        setchaptername(data.data[0].ChapterName);
        setchaptorno(data.data[0].ChapterNo);
        setexerciseCode(data.data[0].ExerciseCode);
        setexerciseid(data.data[0].ExercieId);
        setexcerciseNo(data.data[0].ExerciseNo);
        setfirstqesNo(data.data[0].FirstQuestionNumber);
        setquescount(data.data[0].QuestionCount);
        setsubject(data.data[0].Subject);
        setsolution(data.data[0].Answers);
}
useEffect(()=>{
if(minutes == 15){
    setmin(minutes);
    setsec(seconds);
    setsubmit(true);
}
},[minutes])



useEffect(()=>{
    getdata();
    alert("This test is for 15 minutes");
},[])
   return submit ?
   ( <div className='mainbox'>
   <div className="header mb-2"></div>
   <div className='d-flex justify-content-center'>
       <Card className='innerBoxSub cardBox'
       >
            <h3 style={{textAlign: 'center'}}>{chaptername} - Chapter {chaptoeno}</h3>
                       
                       <hr />

                       <div className="score-section d-flex flex-column">
   <div><b>Total Question / कुल प्रश्न</b> {totalqsn} <br /><b> Correct Question / सही प्रश्न </b> {marks} <br /><b>Time taken / समय लिया </b> = {min} minutes {sec} seconds</div>
  {answers.map((items, ind)=>{return <div>
      <div className="solution-div p-4" style={{color: 'rgb(100, 44, 144)'}}><b>QSNO.</b> - {ind+1} <br /><b>Selected option / चयनित विकल्प</b> - <span className="incorrectop"><b>({items})</b></span> <br /><b>Correct option / सही विकल्प </b> -  ({solution[ind]}) <br />
      <div><img src={`https://dishaquizapp.s3.us-east-1.amazonaws.com/biosol${ind+1}.PNG`} width="80%" /></div>
   </div>
    </div>})}</div>
        </Card>
        </div>
        </div>
     
        ):
   ( 
        <div className='mainbox'>
            <div className="header mb-2"></div>
            <div className='d-flex justify-content-center'>
                <Card className={'innerBox cardBox'}
                >
                    <CardBody className='container'>
                            <h3 style={{textAlign: 'center'}}>Plant Kingdom / वनस्पति जगत</h3>
                            <h5 style={{textAlign: 'center'}}>Introduction and Algae / परिचय और शैवाल </h5>
                            <h6 style={{textAlign: 'center'}}>Beginner Box:3.1 / प्रारंभिक प्रश्नकोश :3.1 </h6>
                        <hr />
                       <div className="d-flex justify-content-between">
                         <div>
                         <CardTitle tag="h4">
                            Question / प्रश्न :<span className='number'>{index+1}</span> /{totalqsn}
                        </CardTitle>
                         </div>
                         <div>
                         <span className='timer'>{minutes} : {seconds}</span>
                         </div>
                       </div>
                       <div className="mt-3 container">
                        <img src={`https://dishaquizapp.s3.us-east-1.amazonaws.com/bio${index+1}.PNG`} alt="Question image"  className='question-img'/>
                       
                        </div> 
                        <div className='mt-3 d-flex justify-content-center'>
                            <h5> Choose correct option below / सही विकल्प का चयन करें</h5>
                        </div>
<br></br>
                        <div className="d-flex justify-content-around">
                            <button   className={ selected == "a" ? 'btn2': 'btn1'}  onClick={()=>{setselected("a");}}>
                                A
                            </button>
                            <button  className={ selected == "b" ? 'btn2': 'btn1'}  onClick={()=>{setselected("b")}}>
                                B
                            </button>
                            <button  className={ selected == "c" ? 'btn2': 'btn1'}  onClick={()=>{setselected("c")}}>
                                C
                            </button >
                            <button  className={ selected == "d" ? 'btn2': 'btn1'}  onClick={()=>{setselected("d")}}>
                                D
                            </button>
                        </div>
                        <div className='mt-3 d-flex flex-column'>
                        <Button  className='btn-2 mt-3 submitBtn' onClick={()=>{Nexts();}} >Next / अगला </Button>
                        <Button  className='btn-2 mt-3 submitBtn' onClick={()=>{ Prevs();}}>Previous / पिछला</Button>
                        </div>

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
